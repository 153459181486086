// export default {
//   apiKey: "your_firebase_api_key",
//   authDomain: "your_firebase_auth_domain",
//   projectId: "your_firebase_project_id",
//   storageBucket: "your_firebase_storage_bucket",
//   messagingSenderId: "your_firebase_messaging_sender_id"
//   databaseURL: "your_firebase_database_url",
// };

export default {
  apiKey: "AIzaSyCxsEpBcO2ke3NxzCw2V-3PpHW5rj3QCyE",
  authDomain: "book-admin-1ec26.firebaseapp.com",
  projectId: "book-admin-1ec26",
  storageBucket: "book-admin-1ec26.appspot.com",
  messagingSenderId: "356817405410",
  appId: "1:356817405410:web:f0c10f4a505f941302e5bb"
};
