import axios from "axios";
import _ from "lodash"

const mgmtCode = localStorage.setItem('mgmtCode', 'mgmt-000001');
const token = localStorage.getItem('id_token');

const currentRoute = window.location.href;
let REFERRER_HEADER = new URL(currentRoute).hostname

export const BASE_URL = 'https://staging.loichucvu.org'
// export const BASE_URL = 'http://149.28.134.133'

var axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

switch (REFERRER_HEADER) {
    case 'localhost':
        axiosInstance.interceptors.request.use(req => {
            req.headers.token = token;
            req.headers.referrer = 'staging.drm.taichinh.care';
            return req;
        });
        break;

    case 'staging.drm.taichinh.care':
        axiosInstance.interceptors.request.use(req => {
            req.headers.token = token;
            req.headers.referrer = REFERRER_HEADER;
            return req;
        });
        break;

    case 'staging.drm.loichucvu.org':
        axiosInstance.interceptors.request.use(req => {
            req.headers.token = token;
            req.headers.referrer = REFERRER_HEADER;
            return req;
        });
        break;

    default:
        axiosInstance.interceptors.request.use(req => {
            req.headers.token = token;
            req.headers.referrer = 'staging.drm.taichinh.care';
            return req;
        });
        break;
}

// export const callAPIVerOne = async (method, url, params) => {
//     const token = localStorage.getItem("id_token") || "";

//     try {
//         switch (REFERRER_HEADER) {
//             case 'localhost':
//                 axios.interceptors.request.use(req => {
//                     req.headers.token = token;
//                     req.headers.referrer = 'staging.drm.taichinh.care';
//                     return req;
//                 });
//                 break;

//             case 'staging.drm.taichinh.care':
//                 axios.interceptors.request.use(req => {
//                     req.headers.token = token;
//                     req.headers.referrer = REFERRER_HEADER;
//                     return req;
//                 });
//                 break;

//             case 'staging.drm.loichucvu.org':
//                 axios.interceptors.request.use(req => {
//                     req.headers.token = token;
//                     req.headers.referrer = REFERRER_HEADER;
//                     return req;
//                 });
//                 break;

//             default:
//                 axios.interceptors.request.use(req => {
//                     req.headers.token = token;
//                     req.headers.referrer = 'staging.drm.taichinh.care';
//                     return req;
//                 });
//                 break;
//         }

//         return axios({
//             method: method,
//             url: `${BASE_URL}${url}`,
//             params: params

//         }).then(
//             res => {
//                 return res.data
//             }
//         ).catch(handleError)

//     } catch (error) {
//         return {
//             result: false,
//             data: {
//                 errCode: 1,
//                 errDetail: error.message || "System had error, please try again!"
//             }
//         };
//     }
// };


export const callAPIVerOne = async (method, url, params, body) => {
    const token = localStorage.getItem("id_token") || "";

    try {
        switch (REFERRER_HEADER) {
            case 'localhost':
                axios.interceptors.request.use(req => {
                    req.headers.token = token;
                    req.headers.referrer = 'staging.drm.taichinh.care';
                    return req;
                });
                break;

            case 'staging.drm.taichinh.care':
            case 'staging.drm.loichucvu.org':
                axios.interceptors.request.use(req => {
                    req.headers.token = token;
                    req.headers.referrer = REFERRER_HEADER;
                    return req;
                });
                break;

            default:
                axios.interceptors.request.use(req => {
                    req.headers.token = token;
                    req.headers.referrer = 'staging.drm.taichinh.care';
                    return req;
                });
                break;
        }

        return axios({
            method: method,
            url: `${BASE_URL}${url}`,
            params: params,
            data: body
        }).then(
            res => {
                return res.data;
            }
        ).catch(handleError);

    } catch (error) {
        return {
            result: false,
            data: {
                errCode: 1,
                errDetail: error.message || "System had error, please try again!"
            }
        };
    }
};



export function handleError(error) {
    if (error.response) {
        console.log('error.response')
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log('Error', error.message);
    }
    console.log(error.config);
    return { errCode: 1, errDetail: 'system error', result: null };
}

export default axiosInstance;