import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import axios from '../../services/axios'


const history = createBrowserHistory();

const fakeApiCall = async (user) => {
  const data = new FormData()
  data.append('email', user.email)
  data.append('password', user.password)
  const res = await axios({
    method: 'POST',
    data: data,
    url: '/api/v1/account/login-by-email'
  })
  return res.data.result.account;
}

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    try {
      const data = yield call(fakeApiCall, payload.user);

      if (data && !data.errCode) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: data.token,
          profile: 'Profile',
          email: data.email,
        });

      } else {
        yield put({ type: actions.LOGIN_ERROR, error: data.errorMessage || 'An error occurred' });
      }
    } catch (error) {
      yield put({ type: actions.LOGIN_ERROR, error: 'An error occurred' });
    }
  });
}


export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {    
    yield localStorage.setItem('id_token', payload.token);
    yield localStorage.setItem('email', payload.email);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    history.push('/');
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    const email = getToken().get('email');

    if (token &&  email) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
        email: email,
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}