export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',

  PAGE_BOOKDASHBOARD: '/bookdashboard',
  PAGE_ADDBOOKOPTION: '/addbookoption',
  PAGE_EDITBOOKDASHBOARD: '/editbookdashboard',
  PAGE_CHAPTER: '/chapter',
  PACKAGE_DETAIL: '/packagedetail',
  PACKAGE_REVIEW_CHAPTER: '/chapterreview',
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
  BOOK_INFO: '/bookinfo',
  BOOK_CONTENT: '/bookcontent',
  BOOK_PRICING: '/bookpricing',
  BOOK_REVIEW: '/bookreview',
  BOOK_SUMMARY: '/booksummary',
  SUBSCRIPTION: '/subscription',
  REGISTER_USERS: '/users',
  COLLECTION: '/collection',
};
